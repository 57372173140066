/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
// pages
import LandingPage from "views/examples/LandingPage.js";
import Professionals from "views/examples/Professionals";
import Programs from "views/examples/Programs";
import Payment from "views/examples/Payment";

// others
import ScrollToTop from "components/ScrollToTop"

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/">
        <Redirect to="/landing-page" />
      </Route>
      <Route
        path="/landing-page"
        render={props => <ScrollToTop><LandingPage {...props} /></ScrollToTop>}
      />
      <Route
        path="/programs"
        render={props => <ScrollToTop><Programs {...props} /></ScrollToTop>}
      />
      <Route
        path="/professionals"
        render={props => <ScrollToTop><Professionals {...props} /></ScrollToTop>}
      />
      <Route
        path="/payment"
        render={props => <ScrollToTop><Payment {...props} /></ScrollToTop>}
      />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
