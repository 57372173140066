import React from 'react';
import { Button } from 'reactstrap';

function Step1(props){
  console.log(props)
  return(
    <>
      <div>
        <h4 style={{marginBottom:'15px', fontWeight:'400', textAlign:'left'}}>
            Pagamento concluído com sucesso
        </h4>
        <h5 style={{textAlign:'left'}}>
            Em breve entraremos em contato informando os dias e horários disponíveis!
        </h5>
        <div style={{justifyContent:'flex-end', display:'flex'}}>
        <Button onClick={()=>props.history.goBack()} style={{marginTop:'20px'}}>
            Voltar
        </Button>
        </div>
      </div>
    </>
  )
}

export default Step1