import React, { useState } from 'react';
import {AvForm, AvGroup, AvField} from 'availity-reactstrap-validation';
import { Button, Input, Form, FormGroup, Label } from 'reactstrap';

function Step2(props){

  function handleValidSubmit(event, values) {
    props.values(values);
    props.nextStep()  
  }

  return(
    <>
      <AvForm
        onValidSubmit={handleValidSubmit}
      >
        <AvGroup>
          <AvField
            name="name"
            label="Nome*"
            required
          />
        </AvGroup>
        <AvGroup>
          <AvField
            name="phone"
            label="Telefone (Whatsapp)*"
            type="text"
            maxLength={11}
            minLength={10}
            required
          />
        </AvGroup>
        <AvGroup>
          <AvField
            name="email"
            label="Email*"
            type="email"
            required
          />
        </AvGroup>
        <AvGroup>
          <AvField
            name="doctor"
            label="Qual o médico de sua preferência?"
            required
          />
        </AvGroup>
        <FormGroup>
          <Label for="exampleText">Descreva sua queixa</Label>
          <Input type="textarea" name="text" id="problem" />
        </FormGroup>
        <Button style={{marginTop:'20px', marginLeft:'10px'}}>
            Prosseguir
        </Button>
      </AvForm>
    </>
  )
}

export default Step2