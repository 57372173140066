import React from 'react';
import { Button } from 'reactstrap';

function Step1(props){
  console.log(props)
  return(
    <>
      <div>
        <h4 style={{marginBottom:'15px', fontWeight:'400', textAlign:'left'}}>
            Termo de Ciência e Consentimento Livre e Esclarecido para se submeter à Teleconsulta
        </h4>
        <h5 style={{textAlign:'left'}}>
            Ao clicar em "Prosseguir", declaro meu
            consentimento à atividade de TELEMEDICINA, atividade
            regulamentada pelo Ministério da Saúde através da Portaria
            nº 467 e pelo Conselho Federal de Medicina, por meio do
            Ofício CFM 1756/2020 – COJUR, dado ao atual cenário de
            COVID-19.
            <br/>
            <br/>
            Declaro ainda que estou ciente de que este atendimento
            não é coberto ainda pelas operadoras de saúde, não havendo
            possibilidade de reembolso das mesmas, bem como me
            comprometo a arcar com os honorários médicos pelo
            atendimento, conforme tabela de honorários vigente.
        </h5>
        <div style={{justifyContent:'flex-end', display:'flex'}}>
        <Button onClick={()=>props.history.goBack()} style={{marginTop:'20px'}}>
            Voltar
        </Button>
        <Button onClick={props.nextStep} style={{marginTop:'20px', marginLeft:'10px'}}>
            Prosseguir
        </Button>
        </div>
      </div>
    </>
  )
}

export default Step1