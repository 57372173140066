import React from "react"
import {Container, Row, Col} from 'reactstrap'
import StaticNavbar from "components/Navbars/StaticNavbar.js"



function Programs() {
    return(
        <>
            <StaticNavbar />
            <div className="main">
                <div className="section text-center">
                    <Container>
                    <Row>
                        <Col className="program" xs={12} sm={12} md={12}>
                            <div >
                                <br/>
                                <h2 >Reabilitação Neurofuncional</h2>
                                <br/>
                                <p >
                                    A reabilitação neurofuncional auxilia os pacientes a desempenhar suas atividades de vida diária como faziam antes da doença ou lesão e/ou readaptar às tarefas, para que sejam executadas de uma forma mais simples e prática. Os objetivos do tratamento são: melhorar a força muscular, sensibilidade, equilíbrio estático e dinâmico; auxiliar no treino da marcha; melhorar a mobilidade e transferências nas atividades diárias; além de prevenir o risco de quedas, espasticidade e deformidades.
                                </p>
                            </div>
                        </Col>
                        <Col className="program" xs={12} sm={12} md={6}>
                            <div >
                                <h5 >
                                    Realidade Virtual
                                </h5>
                                <p >
                                Essa técnica cria um ambiente interativo e multissensorial, através de games, com uso do computador, em tempo real. Realiza tarefas repetidas, orientadas à tarefa e controla o nível de evolução dos exercícios. É uma ferramenta bastante motivadora e lúdica!
                                </p>
                            </div>
                        </Col>
                        <Col className="program" xs={12} sm={12} md={6}>
                            <div >
                                <h5 >
                                Grupo de equilíbrio e prevenção de quedas
                                </h5>
                                <p >
                                Grupo de reabilitação direcionado às pessoas que têm alteração do equilíbrio, parado ou em movimento. Esse tratamento visa melhorar as habilidades motoras nas atividades de vida diária que necessitam de equilíbrio, oferecendo maior independência e qualidade de vida, principalmente aos idosos.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    </Container>
                </div>
                <div className="section text-center">
                    <Container>
                    <Row>
                        <Col className="program" xs={12} sm={12} md={12}>
                            <div >
                                <h2 >Reabilitação Cognitiva</h2>
                                <br/>
                                <p >
                                Envolve um conjunto de estratégias e condutas que visam a melhora ou manutenção das funções cognitivas. Essas funções são: atenção, concentração, memória, linguagem, raciocínio lógico e funções executivas, como controle de comportamental.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    </Container>
                </div>
                <div className="section text-center">
                    <Container>
                    <Row>
                        <Col className="program" xs={12} sm={12} md={12}>
                            <div >
                                <h2 >Academia Terapêutica</h2>
                                <br/>
                            </div>
                        </Col>
                        <Col className="program" xs={12} sm={12} md={4}>
                            <div >
                                <h5 >
                                Consultoria em Envelhecimento Ativo
                                </h5>
                                <p >
                                Promoção de um envelhecimento saudável através da adoção de um estilo de vida ático, com a orientação de atividades físicas prescritas e orientadas de acordo com as necessidades físicas e funcionais do paciente.
                                </p>  
                            </div>
                        </Col>
                        <Col className="program" xs={12} sm={12} md={4}>
                            <div >
                                <h5 >
                                Osteopatia
                                </h5>
                                <p >
                                Intervenção manual sobre os tecidos (articulações, músculos, fáscias, ligamentos, cápsulas, vísceras, tecido nervoso, vascular e linfático) com o objetivo de restabelecer a mobilidade perdida, aliviar dores e devolver equilíbrio ao sistema.
                                </p>
                            </div>
                        </Col>
                        <Col className="program" xs={12} sm={12} md={4}>
                            <div >
                                <h5 >
                                Pilates Solo
                                </h5>
                                <p >
                                Ajuda no fortalecimento global do indivíduo, na flexibilidade e na respiracão, atuando na recuperação ou manutenção do controle funcional em suas atividades diárias e laborais.
                                </p>
                            </div>      
                        </Col> 
                        <Col className="program" xs={12} sm={12} md={6}>
                            <div >
                                <h5 >
                                Grupo de Equilíbrio e Prevenção de Quedas
                                </h5>
                                <p >
                                Grupo de reabilitação direcionado às pessoas que têm alteração do equilíbrio, parado ou em movimento. Esse tratamento visa melhorar as habilidades motoras nas atividades de vida diária que necessitam de equilíbrio, oferecendo maior independência e qualidade de vida, principalmente aos idosos.
                                </p>
                            </div>
                        </Col>
                        <Col className="program" xs={12} sm={12} md={6}>
                            <div >
                                <h5 >
                                Treinamento Funcional para Grupos Especiais
                                </h5>
                                <p >
                                Exercícios físicos direcionados para prevenção de doenças e como coadjuvante no tratamento de doenças neurológicas.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    </Container>      
                </div>
                <div className="section text-center">
                    <Container>
                    <Row>
                        <Col className="program" xs={12} sm={12} md={12}>
                            <div  >
                            <h2 >Atendimento ao Cuidador</h2>
                            <br/>
                            </div>
                        </Col>
                        <Col className="program" xs={12} sm={12} md={6}>
                            <div >
                                <h5 >
                                Atividade Física
                                </h5>
                                <p >
                                Grupo de atividade física com professores capacitados, onde o cuidador pode se exercitar no mesmo horário em que aguarda o seu paciente/familiar ser atendido.
                                </p>
                            </div>
                        </Col>
                        <Col className="program" xs={12} sm={12} md={6}>
                            <div >
                                <h5 >
                                Acompanhamento Psicológico
                                </h5>
                                <p >
                                Grupo de apoio aos cuidadores, para conversar e trocar experiências com outros cuidadores, sob a orientação dos nossos psicólogos.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    </Container>
                </div>
                <div className="section text-center">
                    <Container>
                    <Row>
                        <Col className="program" xs={12} sm={12} md={12}>
                            <div >
                                <h2 >Outros Serviços</h2>
                                <br/>
                            </div>
                        </Col>
                        <Col className="program" xs={12} sm={12} md={4}>
                            <div >
                                <h5 >
                                Atendimento Domiciliar
                                </h5>
                                <p >
                                Atendimento domiciliar para os pacientes que possuem limitações que dificultam sua locomoção até a clínica, contando com fisioterapeutas, terapeutas ocupacionais e fonoaudiólogos. O objetivo é oferecer tratamento personalizado a cada paciente, com praticidade, flexibilidade de horários, em um ambiente confortável e familiar.
                                </p>
                            </div>
                        </Col>
                        <Col className="program" xs={12} sm={12} md={4}>
                            <div >
                                <h5 >
                                Órteses de Membros Superiores
                                </h5>
                                <p >
                                Confeccionadas sob medida em termoplástico de baixa temperatura. São utilizadas para posicionamento funcional, prevenção de deformidades, ganho de amplitude de movimento,  facilitador de função. Deve ser prescrita individualmente, cujo modelo deve estar de acordo com os objetivos propostos.
                                </p>
                            </div>
                        </Col>
                        <Col className="program" xs={12} sm={12} md={4}>
                            <div >
                                <h5 >
                                Adaptações para AVD's e atividades cotidianas
                                </h5>
                                <p >
                                Prescritas e confeccionadas individualmente conforme a necessidade de cada paciente e através de análise minuciosa da atividade. Proporcionam facilitação da função e adequação da tarefa a ser desempenhada.
                                </p>
                            </div>
                        </Col>
                        <Col className="program" xs={12} sm={12} md={4}>
                            <div >
                                <h5 >
                                Adequação de Cadeira de Rodas
                                </h5>
                                <p >
                                Avaliação física, postural e indicação de correções necessárias, com medidas adequadas às demandas do paciente.
                                </p>
                            </div>
                        </Col>

                        <Col className="program" xs={12} sm={12} md={4}>
                            <div >
                                <h5 >
                                Adequação Ambiental
                                </h5>
                                <p >
                                Envolve avaliação do ambiente e projeto de adequações necessárias para mobilidade, conforto e segurança do paciente e cuidador.
                                </p>
                            </div>
                        </Col>
                        <Col className="program" xs={12} sm={12} md={4}>
                            <div >
                                <h5 >
                                Bandagens elásticas
                                </h5>
                                <p >
                                Aplicação de faixa elástica para estimulação sensorial, muscular, alívio de dor, favorecer posicionamento e função.
                                </p>
                            </div>
                        </Col> 
                    </Row> 
                    </Container>
                </div>
            </div>
        </>
    )
}

export default Programs;