import React, { useState } from 'react';
import { PayPalButton } from "react-paypal-button-v2";
import { Button } from 'reactstrap';
import api from '../../../api'

function Step4(props){
  const [isButtonActive, setIsButtonActive] = useState(true)
  return(
    <>
      <h4 style={{marginBottom:'15px', fontWeight:'400', textAlign:'center'}}>
        Pagamento
      </h4>
      <h5>
        Valor da consulta: R$100,00
      </h5>
      <PayPalButton
        amount="100.00"
        currency="BRL"
        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
        onSuccess={async (details, data) => {
          console.log(details, data)
          setIsButtonActive(false)
          let response = await api.post('/consultation', props.values)
          props.goToStep(5)
          console.log(response)
        }}
        options={{
            currency: "BRL",
            clientId: "AaogmLVtOZYA9D64GoTthB6J01ICJxPbiF6GSLpwFkG_x6WCTSiTaHpBfWQc34dz4t507HhzfE51ABrF"
        }}
      />
      <Button active={isButtonActive} onClick={props.previousStep} style={{marginTop:'20px'}}>
          Voltar  
      </Button>
    </>
  )
}

export default Step4