import React from 'react';
import { Button } from 'reactstrap';

function Step2(props){
  return(
    <>
      <div>
        <h5 style={{textAlign:'left'}}>
            Você está ciente e concorda que esta é uma consulta médica não presencial?
            <br/>
            <br/>
            Você está ciente de que seu agendamento será realizado por um dos neurologistas do Instituto Neuron, não necessariamente o neurologista que o acompanha rotineiramente?
            <br/>
            <br/>
            Você está ciente que se o médico achar necessário o atendimento presencial, para avaliação física, você será convocado(a) à comparecer à clínica, em horário individual e sem a presença de acompanhantes?
            <br/>
            <br/>
            Em caso de avaliação presencial você concorda em comparecer utilizando máscara de proteção facial?
            <br/>
            <br/>
            Clique em "Prosseguir" caso concorde
        </h5>
        <div style={{justifyContent:'flex-end', display:'flex'}}>
        <Button onClick={props.previousStep} style={{marginTop:'20px'}}>
            Voltar
        </Button>
        <Button onClick={props.nextStep} style={{marginTop:'20px', marginLeft:'10px'}}>
            Prosseguir
        </Button>
        </div>
      </div>
    </>
  )
}

export default Step2