import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container
} from "reactstrap";

function StaticNavbar() {
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

//   React.useEffect(() => {
//     const updateNavbarColor = () => {
//       if (
//         document.documentElement.scrollTop > 299 ||
//         document.body.scrollTop > 299
//       ) {
//         setNavbarColor("");
//       } else if (
//         document.documentElement.scrollTop < 300 ||
//         document.body.scrollTop < 300
//       ) {
//         setNavbarColor("navbar-transparent");
//       }
//     };

//     window.addEventListener("scroll", updateNavbarColor);

//     return function cleanup() {
//       window.removeEventListener("scroll", updateNavbarColor);
//     };
//   });
  return (
    <Navbar
      className={classnames("fixed-top")}
      color-on-scroll="300"
      expand="lg"
    >
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            to="/landing-page"
            title="Instituto Neuron"
            tag={Link}
          >
            <img src={require("assets/img/navbar_logo.png")} alt="logo_neuron" style={{width:"60%",}}/>
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
              <NavLink to="/programs" tag={Link} onClick={toggleNavbarCollapse}>
                <i className="nc-icon nc-layout-11" /> Programas
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="/professionals"
                tag={Link}
                onClick={toggleNavbarCollapse}
              >
                <i className="nc-icon nc-circle-10" /> Profissionais
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="http://www.institutoneuron.com.br/blog/"
                target="_blank"
                onClick={toggleNavbarCollapse}
              >
                <i className="nc-icon nc-book-bookmark" /> Blog
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.facebook.com/institutoneuroncampinas/"
                target="_blank"
                title="Like us on Facebook"
                onClick={toggleNavbarCollapse}
              >
                <i className="fa fa-facebook-square" />
                <p className="d-lg-none">Facebook</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.instagram.com/institutoneuroncampinas/"
                target="_blank"
                title="Follow us on Instagram"
                onClick={toggleNavbarCollapse}
              >
                <i className="fa fa-instagram" />
                <p className="d-lg-none">Instagram</p>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default StaticNavbar;
