import React, { useState } from "react"
import {Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap'
import StaticNavbar from "components/Navbars/StaticNavbar.js";
import StepWizard from 'react-step-wizard';
import Step1 from './steps/step1';
import Step2 from './steps/step2';
import Step3 from './steps/step3';
import Step4 from './steps/step4';
import Step5 from './steps/step5';

function Payment(props) {

    const [modal, setModal] = useState(true);
    const [formValues, setFormValues] = useState({})
    const toggleModal = () => setModal(!modal);

    return(
        <>
            <StaticNavbar />
            <div className="main">
                <div className="section text-center">
                    <Container>
                    <Row> 
                        <Col>
                        </Col>
                        <Col className="program" xs={12} sm={12} md={6}>
                            <StepWizard>
                                <Step1 {...props}/>
                                <Step2 {...props}/>
                                <Step3 {...props} values={values=>setFormValues(values)}/>
                                <Step4 {...props} values={formValues}/>
                                <Step5 {...props}/>
                            </StepWizard>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    </Container>
                </div>
            </div>           
        </>
    )
}

export default Payment;