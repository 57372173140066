/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";

// reactstrap components
import {
  Card,
  CardTitle,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Button
} from "reactstrap";

// core components
import ItemsCarousel from "react-items-carousel";
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import FisioterapiaIcon from "assets/icons/fisioterapia.svg"
import TerapiaOcupacionalIcon from "assets/icons/terapia_ocupacional.svg"
import EducacaoFisicaIcon from "assets/icons/educacao_fisica.svg"
import FonoaudiologiaIcon from "assets/icons/fonoaudiologia.svg"
import NeurologiaIcon from "assets/icons/neurologia_clinica.svg"
import NutricaoIcon from "assets/icons/nutricao.svg"
import PsicologiaIcon from "assets/icons/psicologia.svg"
import clinica1 from 'assets/img/fotos-clinica/crop/clinica1.jpg'
import clinica2 from 'assets/img/fotos-clinica/crop/clinica2.jpg'
import clinica3 from 'assets/img/fotos-clinica/crop/clinica3.jpg'
import clinica4 from 'assets/img/fotos-clinica/crop/clinica4.jpg'
import clinica5 from 'assets/img/fotos-clinica/crop/clinica5.jpg'
import clinica6 from 'assets/img/fotos-clinica/crop/clinica6.jpg'
import clinica7 from 'assets/img/fotos-clinica/crop/clinica7.jpg'


function LandingPage(props) {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [modal, setModal] = useState(true);
  const toggleModal = () =>{
    setModal(!modal);
  }
  let items = [clinica1,clinica2,clinica3,clinica4,clinica5,clinica6,clinica7]
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <Modal isOpen={modal} toggle={toggleModal} style={{width:'70%', maxWidth:'none', marginRight:'auto', marginLeft:'auto'}}>
        <ModalHeader>
          <h5>Comunicado</h5>
        </ModalHeader>
        <ModalBody>
          <h5>
            Campinas, 20 de março de 2020.
            <br/>
            <br/>
            Caros pacientes e familiares,
            <br/>
            Seguindo as recomendações do Plano de Contingência Nacional para Infecção Humana pelo novo Coronavírus COVID-19 do Ministério da Saúde, comunicamos que a clínica estará fechada a partir de <b>sábado 21/03/2020</b> para atendimentos presenciais. Cumprindo o nosso papel de prezar pela segurança de nossos pacientes e profissionais e considerando que os nossos atendimentos são eletivos (não fazemos atendimento de urgência/ emergência) de pacientes prioritariamente do grupo de maior risco para infecção grave, seguiremos fechados nesse período crítico.
            Para que os nossos pacientes não fiquem sem receituário médico de controle especial, abriremos a clínica na <b>quarta-feira 25/03/2020 das 08:00-12:00</b> e <b>sexta-feira 27/03/2020 das 08:00-12:00</b> para entrega das receitas. Reforçamos a recomendação de que neste dia algum familiar ou cuidador busque a receita do paciente, para que os idosos permaneçam em casa mais protegidos.
            Lembramos que os atendimentos domiciliares da equipe de reabilitação neurológica permanecerão disponíveis.
            Para facilitar a comunicação com os profissionais da clínica neste período pedimos para enviarem dúvidas e solicitações para o e-mail da clínica <b>contato@institutoneuron.com.br</b> ou <b>WhatsApp (19) 9994-6055</b>.
            Estamos buscando alternativas seguras para continuar a assistência de nossos pacientes. 
            À disposição e gratos pela compreensão,
            <br/>
            <br/>
            Instituto Neuron
          </h5>
        </ModalBody>
      </Modal>
      <ExamplesNavbar />
      <LandingPageHeader />
      <div className="main">

      <div className="section text-center">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h2 className="title">Consultas Online</h2>
              <h5 className="description">

              </h5>
              <br />
              <Button className="btn-link" color="info" onClick={()=>props.history.push('/payment')}>
                Agende sua consulta
              </Button>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="section text-center">
        <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="8">
            <h2 className="title">O Começo</h2>
            <h5 className="description">
            O Instituto Neuron surgiu do sonho de criarmos um espaço digno de acolher o paciente neurológico, com suas demandas e particularidades. Anos de estudo e dedicação médica nos ajudam a fazer os mais difíceis diagnósticos, mas não a oferecer o melhor cuidado ao nosso paciente.
            Na busca de um atendimento que não se limite à prescrição médica, reunimos um grupo de profissionais que, além de especialização e experiência em neurologia, compartilham o amor à vida e ao ser humano. O nosso objetivo é oferecer um cuidado diferenciado, como acreditamos que deve ser.
            </h5>
            <br />
          </Col>
        </Row>
        <br />
        <br />
        </Container>
      </div>

      <div className="section text-center">
          <Container>
            <h2 className="title">A Clínica</h2>
            <h5 className="description">
              O Instituto Neuron é um espaço único e especializado em reabilitação neurológica. Oferecemos cuidado especializado em Neurologia, buscando reconhecer as particularidades e potencializar as habilidades de cada paciente. Nosso objetivo é proporcionar a melhor terapêutica para que o processo de recuperação ocorra de forma segura, eficaz e venha sempre acompanhado de bem-estar.É preciso reabilitar. É preciso reaprender.
            </h5>
            <br/>
            <br/>
            <Row>
              <Col>
                <div 
                  style={{
                    "padding":"0 60px",
                    "maxWidth":800,
                    "margin":"0 auto"  
                  }}
                  className="mobile-hide"
                >
                <ItemsCarousel
                  infiniteLoop={false}
                  gutter={12}
                  activePosition={'center'}
                  chevronWidth={60}
                  disableSwipe={false}
                  alwaysShowChevrons={false}
                  numberOfCards={2}
                  slidesToScroll={2}
                  outsideChevron={true}
                  showSlither={false}
                  firstAndLastGutter={false}
                  activeItemIndex={activeItemIndex}
                  requestToChangeActive={value => setActiveItemIndex(value)}
                  rightChevron={'>'}
                  leftChevron={'<'}
                >
                  { items.map((item, i) => <div key={i}>{ <img style={{height: 200, objectFit: "cover !important"}} src={item} alt="foto da clinica neurologica"/> }</div>) }
                </ItemsCarousel>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section text-center">
          <Container>
          <h2 className="title">Especialidades</h2>
            <Row>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <img src={FisioterapiaIcon} alt="fisioterapia"/>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Fisioterapia</h4>
                    <p className="description">
                      A reabilitação neurofuncional tem como função melhorar a capacidade funcional e percepção da qualidade de vida do indivíduo, reinserindo-o ao convívio social.
                    </p>
                    {/*<Button className="btn-link" color="info" href="#pablo">
                      See more
                      </Button>*/}
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <img src={FonoaudiologiaIcon} alt="fonoaudiologia"/>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Fonoaudiologia</h4>
                    <p>
                      Área responsável pelo cuidado das funções da linguagem oral e escrita, da voz, da articulação da fala e da deglutição, entre outros. Realizamos acompanhamento terapêutico para pacientes com objetivos específicos.
                    </p>
                    {/*<Button className="btn-link" color="info" href="#pablo">
                      See more
                    </Button>*/}
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <img src={TerapiaOcupacionalIcon} alt="terapia_ocupacional"/>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Terapia Ocupacional</h4>
                    <p>
                      A Terapia Ocupacional tem como objetivo principal resgatar papéis sociais e independência de pessoas que tiveram comprometimento no desempenho das atividades ocupacionais significativas, desde cuidados pessoais, até trabalho, hobbies, participação na comunidade, entre outras.
                    </p>
                    {/*<Button className="btn-link" color="info" href="#pablo">
                      See more
                  </Button>*/}
                  </div>
                </div>
              </Col>
              
            </Row>
            <br />
            <br />
            <Row>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <img src={PsicologiaIcon} alt="psicologia"/>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Psicologia</h4>
                    <p>
                      Estuda e faz intervenções nos processos mentais, incluindo o comportamento do ser humano e suas interações com o ambiente. O psicólogo explora funções cognitivas como atenção, memória e emoções, além dos aspectos da personalidade.
                    </p>
                    {/*<Button className="btn-link" color="info" href="#pablo">
                      See more
                    </Button>*/}
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                    <div className="icon icon-info">
                      <img src={EducacaoFisicaIcon} alt="educacao_fisica"/>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Educação Física</h4>
                      <p>
                        Aperfeiçoamento, controle e manutenção da saúde do corpo e da mente do ser humano. A pratica de exercícios regulares é capaz de agir tanto na prevenção de doenças crônicas como também no tratamento não-farmacológico das doenças já estabelecidas.
                      </p>
                      {/*<Button className="btn-link" color="info" href="#pablo">
                        See more
                      </Button>*/}
                    </div>
                  </div>
              </Col>
              <Col md="4">
                <div className="info">
                    <div className="icon icon-info">
                      <img src={NutricaoIcon} alt="nutricao"/>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Nutrição</h4>
                      <p>
                        Tem o objetivo de diagnosticar as condições nutricionais do paciente, atuando na reeducação alimentar e na elaboração do plano alimentar funcional e individualizado, com enfoquenos distúrbios neurológicos como: depressão, ansiedade, insônia, enxaqueca, Parkinson, Alzheimer e acidente vascular cerebral.
                      </p>
                      {/*<Button className="btn-link" color="info" href="#pablo">
                        See more
                    </Button>*/}
                    </div>
                  </div>
              </Col>
            </Row>
            <Row>
              <Col>
              </Col>
              <Col md="4">
                  <div className="info">
                      <div className="icon icon-info">
                        <img src={NeurologiaIcon} alt="neurologia"/>
                      </div>
                      <div className="description">
                        <h4 className="info-title">Neurologia Clínica</h4>
                        <p>
                          É a especialidade médica que estuda e trata doenças do sistema nervoso, como AVC, epilepsia, dores de cabeça, neuropatias, distúrbios da memória e do sono. Venha conhecer o Instituto Neuron, que conta com Neurologistas especializados e empenhados em cuidar de pacientes com doenças neurológicas.
                        </p>
                        {/*<Button className="btn-link" color="info" href="#pablo">
                          See more
                    </Button>*/}
                      </div>
                    </div>
                </Col>
                <Col>
                </Col>
            </Row>
          </Container>
        </div>

        <div className="section section-dark text-center">
          <Container>
            <h2 className="title">Histórias</h2>
            <Row>
              <Col md="4">
                <Card className="card-plain">
                  <div className="author">
                    <CardTitle tag="h4">Antônio Augusto Bennini</CardTitle>
                    <h6 className="card-category">Paciente</h6>
                  </div>
                  <p className="card-description text-center">
                    Serei eternamente grato a você e aos seus colegas de trabalho do Instituto Neuron. Hoje, sou um intusiasta da terapia ocupacional. Sou suspeito, pois fui tratado pela melhor terapeuta ocupacional do mundo.
                  </p>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-plain">
                    <div className="author">
                      <CardTitle tag="h4">Clarice Hatamoto</CardTitle>
                      <h6 className="card-category">Paciente</h6>
                    </div>
                    <p className="card-description text-center">
                    A terapia ocupacional me ajudou não só na minha reabilitação motora, como também a reconstrução da confiança em mim mesma. Hoje consigo realizar tarefas que pensava serem impossíveis, como trabalhar, ou, sendo mais simples, passar um dia sozinha em casa.
                    </p>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-plain">
                  
                    <div className="author">
                      <CardTitle tag="h4">João Francisco Marques Neto</CardTitle>
                      <h6 className="card-category">Paciente</h6>
                    </div>
                    <p className="card-description text-center">
                    Eu acordei não enxergando bem, não enxergando praticamente nada. Não falava nada e andava muito pouco. Aqui no Neuron que eu me acertei e me acertei excelentemente. As meninas são fantásticas, bastante competentes e vestem muito a camisa de todos os pacientes que aqui estão.
                    </p>
                  
                </Card>
              </Col>
            </Row>
            <br/>
            <br/>
            
          </Container>
        </div>
        <div className="section landing-section" id="contact">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center">Entre em contato</h2>
              </Col>
            </Row>
            <br/>
            <br/>
            <Row>
              <Col>
              </Col>
              <Col md="4">
                <h5><i className="nc-icon nc-mobile" style={{marginRight:"15px"}} />(19) 33072006</h5>
                <br/>
                <h5><i className="nc-icon nc-email-85" style={{marginRight:"15px"}}/>contato@institutoneuron.com.br</h5>
                <br/>
                <h5><i className="nc-icon nc-pin-3" style={{marginRight:"15px"}}/>Av. Dr. Heitor Penteado, 610 - Jardim Nossa Sra. Auxiliadora, Campinas</h5>
              </Col>
              <Col md="4">
                <img src={require("assets/img/contact.jpg")} alt="contact" style={{width:"40%", marginLeft:"auto", marginRight:"auto",display:"block"}}/>
              </Col>
              <Col>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <br/>
      <br/>
      <br/>
    </>
  );
}

export default LandingPage;
