import React from "react"
import {Container, Row, Col, Card, CardBody, CardTitle} from 'reactstrap'
import StaticNavbar from "components/Navbars/StaticNavbar.js";


function Professionals() {
    return(
        <>
            <StaticNavbar /> 
            <div className="main">
                <div className="section text-center">
                    <Container>
                        <h2>Neurologia</h2>
                        <Row>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                <div className="card-avatar">
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        src={require("assets/img/profissionais/Dra. Barbara Pereira Braga Alexandre.JPG")}
                                        className="img-avatar"
                                    />
                                    </a>
                                </div>
                                <CardBody>
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <div className="author">
                                        <CardTitle tag="h4">Dra. Barbara Pereira Braga Alexandre</CardTitle>
                                        <h6 className="card-category">Médica Neurologista</h6>
                                    </div>
                                    </a>
                                </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                <div className="card-avatar">
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        src={require("assets/img/profissionais/Dr. Charlington Moreira Cavalcante.JPG")}
                                        className="img-avatar"
                                    />
                                    </a>
                                </div>
                                <CardBody>
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <div className="author">
                                        <CardTitle tag="h4">Dr. Charlington Moreira Cavalcante</CardTitle>
                                        <h6 className="card-category">Médico Neuro-Pediatra</h6>
                                    </div>
                                    </a>
                                </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                <div className="card-avatar">
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        src={require("assets/img/profissionais/Dr. Fabricio Buchdid Cardoso.JPG")}
                                        className="img-avatar"
                                    />
                                    </a>
                                </div>
                                <CardBody>
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <div className="author">
                                        <CardTitle tag="h4">Dr. Fabricio Buchdid Cardoso</CardTitle>
                                        <h6 className="card-category">Médico Neurologista</h6>
                                    </div>
                                    </a>
                                </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                <div className="card-avatar">
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        src={require("assets/img/profissionais/Dra. Flavia Bueno.JPG")}
                                        className="img-avatar"
                                    />
                                    </a>
                                </div>
                                <CardBody>
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <div className="author">
                                        <CardTitle tag="h4">Dra. Flávia Fagundes Bueno</CardTitle>
                                        <h6 className="card-category">Médica Neurologista</h6>
                                    </div>
                                    </a>
                                </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                <div className="card-avatar">
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        src={require("assets/img/profissionais/Dra. Isabela Bruzzi B. Paraguay.JPG")}
                                        className="img-avatar"
                                    />
                                    </a>
                                </div>
                                <CardBody>
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <div className="author">
                                        <CardTitle tag="h4">Dra. Isabela Bruzzi B. Paraguay</CardTitle>
                                        <h6 className="card-category">Médica Neurologista</h6>
                                    </div>
                                    </a>
                                </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                <div className="card-avatar">
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        src={require("assets/img/profissionais/Dra. Lenise Valler.jpg")}
                                        className="img-avatar"
                                    />
                                    </a>
                                </div>
                                <CardBody>
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <div className="author">
                                        <CardTitle tag="h4">Dra. Lenise Valler</CardTitle>
                                        <h6 className="card-category">Médica Neurologista</h6>
                                    </div>
                                    </a>
                                </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                <div className="card-avatar">
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        src={require("assets/img/profissionais/Dra. Vanessa Hachiman.JPG")}
                                        className="img-avatar"
                                    />
                                    </a>
                                </div>
                                <CardBody>
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <div className="author">
                                        <CardTitle tag="h4">Dra. Vanessa Hachiman</CardTitle>
                                        <h6 className="card-category">Médica Neurologista</h6>
                                    </div>
                                    </a>
                                </CardBody>
                                </Card>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <h2>Fisioterapia</h2>
                        {/* <Row>
                            
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                <div className="card-avatar">
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        src={require("assets/img/profissionais/Bruna Nascimento.JPG")}
                                        className="img-avatar"
                                    />
                                    </a>
                                </div>
                                <CardBody>
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <div className="author">
                                        <CardTitle tag="h4">Bruna Nascimento</CardTitle>
                                        <h6 className="card-category">Fisioterapeuta</h6>
                                    </div>
                                    </a>
                                </CardBody>
                                </Card>
                            </Col>
                             <Col md="4">
                                <Card className="card-profile card-plain">
                                <div className="card-avatar">
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        src={require("assets/img/profissionais/Helena Aparecida Veiga da Silva.jpg")}
                                        className="img-avatar"
                                    />
                                    </a>
                                </div>
                                <CardBody>
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <div className="author">
                                        <CardTitle tag="h4">Helena Aparecida Veiga da Silva</CardTitle>
                                        <h6 className="card-category">Fisioterapeuta</h6>
                                    </div>
                                    </a>
                                </CardBody>
                                </Card>
                            </Col> 
                        </Row> */}
                        <Row>
                            <Col>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                <div className="card-avatar">
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        src={require("assets/img/profissionais/Barbara Cristina Silva Guedes Martins.JPG")}
                                        className="img-avatar"
                                    />
                                    </a>
                                </div>
                                <CardBody>
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <div className="author">
                                        <CardTitle tag="h4">Bárbara Cristina Silva Guedes Martins</CardTitle>
                                        <h6 className="card-category">Fisioterapeuta</h6>
                                    </div>
                                    </a>
                                </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                <div className="card-avatar">
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        src={require("assets/img/profissionais/Sara Regina Meira Almeida.JPG")}
                                        className="img-avatar"
                                    />
                                    </a>
                                </div>
                                <CardBody>
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <div className="author">
                                        <CardTitle tag="h4">Sara Regina Meira Almeida</CardTitle>
                                        <h6 className="card-category">Fisioterapeuta</h6>
                                    </div>
                                    </a>
                                </CardBody>
                                </Card>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <h2>Educação Física</h2>
                        <Row>
                            <Col>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                <div className="card-avatar">
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        src={require("assets/img/profissionais/Giovanna Shiomi.jpeg")}
                                        className="img-avatar"
                                    />
                                    </a>
                                </div>
                                <CardBody>
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <div className="author">
                                        <CardTitle tag="h4">Giovanna Shiomi Wopereis</CardTitle>
                                        <h6 className="card-category">Educadora Física</h6>
                                    </div>
                                    </a>
                                </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                <div className="card-avatar">
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        src={require("assets/img/profissionais/Mariana.jpeg")}
                                        className="img-avatar"
                                    />
                                    </a>
                                </div>
                                <CardBody>
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <div className="author">
                                        <CardTitle tag="h4">Mariana Barbosa Santos</CardTitle>
                                        <h6 className="card-category">Educadora Física</h6>
                                    </div>
                                    </a>
                                </CardBody>
                                </Card>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <h2>Terapia Ocupacional</h2>
                        <Row>
                            <Col>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                <div className="card-avatar">
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        src={require("assets/img/profissionais/Meire.JPG")}
                                        className="img-avatar"
                                    />
                                    </a>
                                </div>
                                <CardBody>
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <div className="author">
                                        <CardTitle tag="h4">Meire Oliveira</CardTitle>
                                        <h6 className="card-category">Terapeuta Ocupacional</h6>
                                    </div>
                                    </a>
                                </CardBody>
                                </Card>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <h2>Fonoaudiologia</h2>
                        <Row>
                            <Col>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                <div className="card-avatar">
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        src={require("assets/img/profissionais/Thais Bento Fonsechi.JPG")}
                                        className="img-avatar"
                                    />
                                    </a>
                                </div>
                                <CardBody>
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <div className="author">
                                        <CardTitle tag="h4">Thaís Bento Fonsechi</CardTitle>
                                        <h6 className="card-category">Fonoaudióloga</h6>
                                    </div>
                                    </a>
                                </CardBody>
                                </Card>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <h2>Psicologia</h2>
                        <Row>
                            <Col>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                <div className="card-avatar">
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        src={require("assets/img/profissionais/white.png")}
                                        className="img-avatar"
                                    />
                                    </a>
                                </div>
                                <CardBody>
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <div className="author">
                                        <CardTitle tag="h4">Jéssica Elias Vicentini</CardTitle>
                                        <h6 className="card-category">Psicóloga</h6>
                                    </div>
                                    </a>
                                </CardBody>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="card-profile card-plain">
                                <div className="card-avatar">
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        src={require("assets/img/profissionais/Mateus Henrique Nogueira.jpeg")}
                                        className="img-avatar"
                                    />
                                    </a>
                                </div>
                                <CardBody>
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <div className="author">
                                        <CardTitle tag="h4">Mateus Henrique Nogueira</CardTitle>
                                        <h6 className="card-category">Psicólogo</h6>
                                    </div>
                                    </a>
                                </CardBody>
                                </Card>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default Professionals;